import logo from './logo.svg';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Employee from './pages/Employee';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/:id" element={<Employee/>}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App;
