import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Table from '../components/table';
import { DataEmployee } from '../json/employee';

async function filterById(jsonObject, id) {
  return await jsonObject.filter( function(jsonObject) {
    return (jsonObject['emplyeeId'] == id);
  })[0];
}

function Employee() {
  const [employeeData, setEmployeeData] = useState([])
  const location = useLocation();

  useEffect(() => {
    filterById(DataEmployee, location.pathname.substr(1,))
    .then((obj) => setEmployeeData(obj))
  }, [])
  
  
  console.log('searchParams: ' + location.pathname.substr(1,))
  return (
    <Table employeeData={employeeData}/>
    // <>dkljlsk</>
  )
}

export default Employee