export const DataEmployee = [
  {
    "emplyeeId": 112,
    "Name": "Warisha Shahzaib Khan",
    "PassportNumber": "HG4117303",
    "DOB": "24 March 1990",
    "Salary": "3500 USD",
    "Employment_Status": "Present",
    "Role": "Chief Financial Officer",
    "link": "https://employee.theteamfreelance.com/112"
  }
]