import React from 'react'
import { employeePic, greenTick, ttfLogo } from '../assets/assets'

function Table({employeeData}) {

  return (
    <div className='parentDiv'>
      <div className = 'imgDiv'>
        <img src={ttfLogo} alt='logo' className= 'img1'/>
      </div>
      <div className='mainHeader'>
        <h1>Employment Details </h1>
        <img className='employeeImg' src={employeePic} alt='logo' />
      </div>
      <div className='tableMain'>
        <table >
          <tr>
            <th style={{ textAlign: 'left', paddingTop: 10 }}>Name </th>
            <td>{employeeData.Name} <img src={greenTick} alt='logo' style={{width: '13px', 'marginLeft': '10px'}}/></td>
          </tr>
          <tr>
            <th style={{ textAlign: 'left', paddingTop: 10 }}>Designation </th>
            <td>{employeeData.Role} <img src={greenTick} alt='logo' style={{width: '13px', 'marginLeft': '10px'}}/></td>
          </tr>
          <tr>
            <th style={{ textAlign: 'left', paddingTop: 10 }}>Passport Number </th>
            <td>{employeeData.PassportNumber} <img src={greenTick} alt='logo' style={{width: '13px', 'marginLeft': '10px'}}/></td>
          </tr>
          <tr>
            <th style={{ textAlign: 'left', paddingTop: 10 }}>Date of Birth </th>
            <td>{employeeData.DOB} <img src={greenTick} alt='logo' style={{width: '13px', 'marginLeft': '10px'}}/></td>
          </tr>
          <tr>
            <th style={{ textAlign: 'left', paddingTop: 10 }}>Salary </th>
            <td>{employeeData.Salary} <img src={greenTick} alt='logo' style={{width: '13px', 'marginLeft': '10px'}}/></td>
          </tr>
          <tr>
            <th style={{ textAlign: 'left', paddingTop: 10 }}>Employment Status </th>
            <td>{employeeData.Employment_Status} <img src={greenTick} alt='logo' style={{width: '13px', 'marginLeft': '10px'}}/></td>
          </tr>
          {/* <tr>
            <th style={{ textAlign: 'left', paddingTop: 10 }}>Link </th>
            <td>{employeeData.link}</td>
          </tr> */}
        </table>
      </div>
        {/* <div className = 'imgDiv2'> */}
          
        {/* </div> */}
      </div>
  )
}

export default Table